import React from 'react';
import { Control } from 'react-hook-form';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ProjectID,
  ProjectNanoID
} from '../../../../../../projects/projectsTypes';
import { S3MultipartUploadOnFileUploaded } from '../../../../../../s3Multipart/hooks/useS3MultipartUpload';
import { TaskID, TaskNanoID } from '../../../../../../tasks/tasksTypes';

import { ItemCreateMessageFormData } from '../../hooks/useItemCreateMessageForm';

import { CreateMessageWithTodoItemsButton } from '../../../../../../todoItems/components/modalButtons/CreateMessageWithTodoItemsButton';
import { ModalDropzoneFileAttachmentsField } from '../../../../../../../helpers/FormFields/ModalDropzoneFileAttachmentsField';
import { ModalSelectAttachmentsField } from '../../../../../../../helpers/FormFields/ModalSelectAttachmentsField';
import { ModalSelectProductsField } from '../../../../../../../helpers/FormFields/ModalSelectProductsField';
import { ModalSelectLifestylesField } from '../../../../../../../helpers/FormFields/ModalSelectLifestylesField';
import { ModalSelectMaterialsField } from '../../../../../../../helpers/FormFields/ModalSelectMaterialsField';
import { ModalColorsField } from '../../../../../../../helpers/FormFields/ModalColorsField';

import { Translate } from '../../../../../../../helpers/Translate';
import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { IsDisabled } from '../../../../../../../types';
import { MessageFields } from '../../../../../messagesTypes';
import { MessagesPermissions } from '../../../../../messagesConstants';

import {
  words,
  colorsKeys,
  filesKeys,
  lifestylesKeys,
  materialsKeys,
  productsKeys,
  todoItemsKeys
} from '../../../../../../../locales/keys';

interface ItemCreateMessageAttachDropdownProps {
  control: Control<ItemCreateMessageFormData>;
  disabled?: IsDisabled;
  messageVisibleForClient?: boolean;
  onFileUploaded?: S3MultipartUploadOnFileUploaded;
  outerFilesLoading?: boolean;
  projectId?: ProjectID;
  projectNanoId?: ProjectNanoID;
  setFileAttachmentsModalOpened: (opened: boolean) => void;
  taskId?: TaskID;
  taskNanoId?: TaskNanoID;
  withAttachLifestylesButton?: boolean;
  withAttachMaterialsButton?: boolean;
  withAttachProductsButton?: boolean;
  withFullscreenDropzone?: boolean;
}

function ItemCreateMessageAttachDropdown({
  control,
  disabled,
  messageVisibleForClient,
  onFileUploaded,
  outerFilesLoading,
  projectId,
  projectNanoId,
  setFileAttachmentsModalOpened,
  taskId,
  taskNanoId,
  withAttachLifestylesButton,
  withAttachMaterialsButton,
  withAttachProductsButton,
  withFullscreenDropzone
}: ItemCreateMessageAttachDropdownProps) {
  return (
    <TooltipDropdownHelper
      buttonClassName="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0"
      disabled={disabled}
      dropdownPlacement={PopoverPlacement.TOP_START}
      icon={IconsEnum.PLUS_CIRCLE_OUTLINE}
      iconClassName="h-5 w-5"
      tooltipI18nText={words.actions}
    >
      <div className="px-2 pt-2 pb-1">
        <div className="px-2 text-gray-400 dark:text-gray-400 text-xs leading-5">
          <Translate id={words.attach} />
          <hr className="flex-1 dark:border-gray-600" />
        </div>
      </div>

      <ModalDropzoneFileAttachmentsField
        className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
        name={MessageFields.FILE_ATTACHMENT_IDS}
        control={control}
        type="fileAttachments"
        icon={IconsEnum.UPLOAD_SOLID}
        iconClassName="w-5 h-5 mr-2"
        i18nTitle={filesKeys.attach}
        i18nSubmitText={filesKeys.attach}
        modalIcon={IconsEnum.UPLOAD_SOLID}
        i18nText={filesKeys.fileFromComputer}
        withFullscreenDropzone={withFullscreenDropzone}
        disabled={outerFilesLoading}
        onFileUploaded={onFileUploaded}
        setModalOpened={setFileAttachmentsModalOpened}
        withoutInitialFocus
        withLd={!!taskNanoId}
      />
      <ModalSelectAttachmentsField
        className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
        name={MessageFields.ATTACHED_FILE_ATTACHMENT_IDS}
        control={control}
        icon={IconsEnum.FOLDER_ALT_SOLID}
        iconClassName="w-5 h-5 mr-2"
        i18nTitle={filesKeys.selectFiles}
        i18nSubmitText={filesKeys.attach}
        i18nText={filesKeys.fileFromProject}
        projectNanoId={projectNanoId}
        withoutInitialFocus
      />
      {withAttachProductsButton ? (
        <ModalSelectProductsField
          className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
          name={MessageFields.SELECTED_PRODUCT_IDS}
          control={control}
          icon={IconsEnum.CUBE_SOLID}
          iconClassName="w-5 h-5 mr-2"
          i18nTitle={productsKeys.attach}
          i18nSubmitText={productsKeys.attach}
          modalIcon={IconsEnum.CUBE_SOLID}
          i18nText={productsKeys.singular}
          withoutInitialFocus
        />
      ) : null}
      {withAttachLifestylesButton ? (
        <ModalSelectLifestylesField
          className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
          name={MessageFields.SELECTED_LIFESTYLE_IDS}
          control={control}
          icon={IconsEnum.RULERS_SOLID}
          iconClassName="w-5 h-5 mr-2"
          i18nTitle={lifestylesKeys.attach}
          i18nSubmitText={lifestylesKeys.attach}
          modalIcon={IconsEnum.RULERS_SOLID}
          i18nText={lifestylesKeys.singular}
          withoutInitialFocus
        />
      ) : null}
      {withAttachMaterialsButton ? (
        <ModalSelectMaterialsField
          className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
          name={MessageFields.SELECTED_MATERIAL_IDS}
          control={control}
          icon={IconsEnum.MATERIAL_SOLID}
          iconClassName="w-5 h-5 mr-2"
          i18nTitle={materialsKeys.attach}
          i18nSubmitText={materialsKeys.attach}
          modalIcon={IconsEnum.MATERIAL_OUTLINE}
          i18nText={materialsKeys.singular}
          withoutInitialFocus
        />
      ) : null}

      <ModalColorsField
        className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
        name={MessageFields.COLORS}
        control={control}
        icon={IconsEnum.COLOR_SWATCH_SOLID}
        iconClassName="w-5 h-5 mr-2"
        i18nTitle={colorsKeys.attach}
        i18nSubmitText={colorsKeys.attach}
        modalIcon={IconsEnum.COLOR_SWATCH_OUTLINE}
        i18nText={colorsKeys.colorSwatch}
        withoutInitialFocus
      />

      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_ATTACH_TODO_LIST_BUTTON}
      >
        {(projectId || taskId) && (
          <CreateMessageWithTodoItemsButton
            className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
            {...(projectId
              ? { projectId, projectNanoId }
              : { taskNanoId, taskId })}
            visibleForClient={messageVisibleForClient}
            i18nText={todoItemsKeys.plural}
            icon={IconsEnum.LIST_TODO_SOLID}
            iconClassName="w-5 h-5 mr-2"
          />
        )}
      </CheckPermissions>
    </TooltipDropdownHelper>
  );
}

export default ItemCreateMessageAttachDropdown;
