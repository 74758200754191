import { useState } from 'react';

import {
  ItemMessagesListMessageFileFileAttachment,
  ItemMessagesListMessageFileMessage
} from './ItemMessagesListMessageFile.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { MessagesListMessageFileAttachmentButtons } from '../../../MessagesListMessage/buttons/MessagesListMessageFileAttachmentButtons';
import { ItemMessagesListMessageFile3dModel } from './ItemMessagesListMessageFile3dModel';
import { ItemMessagesListMessageFileBase } from './ItemMessagesListMessageFileBase';
import { ItemMessagesListMessageFileLdModel } from './ItemMessagesListMessageFileLdModel';

import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonTarget } from '../../../../../../../helpers/tooltips/TooltipSingletonTarget';
import { getFileAttachmentName } from '../../../../../../fileAttachments/utils/getFileAttachmentName';

import { Files } from '../../../../../../../utils/Files';

interface ItemMessagesListMessageFileProps {
  messagesCacheKey?: FetchMessagesCacheKey;
  fileAttachment: ItemMessagesListMessageFileFileAttachment;
  message: ItemMessagesListMessageFileMessage;
  isVisibleForClient: boolean;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageFile({
  messagesCacheKey,
  fileAttachment,
  message,
  isVisibleForClient,
  tooltipSingleton
}: ItemMessagesListMessageFileProps) {
  const currentUser = useCurrentUser();

  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const fileName = getFileAttachmentName(fileAttachment);

  const taskLdSku = message.task?.ldSku;
  const taskLdCustomerId = message.task?.ldCustomerId;

  const withViewLdFile = !!(
    currentUser.currentTeam?.useLdApiAt &&
    fileAttachment.ldFileAt &&
    taskLdSku &&
    taskLdCustomerId
  );

  const tooltipView = fileName ? (
    <Tooltip
      placement={TooltipPlacement.TOP}
      referenceElement={referenceTooltipElement}
      className="break-words"
      withArrow
    >
      <div className="break-words">{fileName}</div>
    </Tooltip>
  ) : null;

  const tooltipSingletonView = fileName ? (
    <TooltipSingletonTarget referenceElement={referenceTooltipElement}>
      <div className="break-words">{fileName}</div>
    </TooltipSingletonTarget>
  ) : null;

  const messageFileItem = Files.isPreview3dModel(fileAttachment.file) ? (
    <ItemMessagesListMessageFile3dModel
      fileAttachment={fileAttachment}
      message={message}
      isVisibleForClient={isVisibleForClient}
    />
  ) : (
    <ItemMessagesListMessageFileBase
      fileAttachment={fileAttachment}
      isVisibleForClient={isVisibleForClient}
    />
  );

  return (
    <div
      className="group/item flex-grow max-w-60 relative"
      ref={setReferenceTooltipElement}
    >
      <div className="flex gap-1 relative h-full rounded bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800 p-2 pr-3 focus-within:ring-base focus-within:ring-offset-0 overflow-hidden transition-colors duration-150 ease-out">
        {withViewLdFile ? (
          <ItemMessagesListMessageFileLdModel
            fileAttachment={fileAttachment}
            isVisibleForClient={isVisibleForClient}
            taskLdCustomerId={taskLdCustomerId}
            taskLdSku={taskLdSku}
          />
        ) : (
          messageFileItem
        )}
      </div>

      <MessagesListMessageFileAttachmentButtons
        addClassName="bottom-0"
        fileAttachment={fileAttachment}
        message={message}
        messagesCacheKey={messagesCacheKey}
        taskLdCustomerId={taskLdCustomerId}
        taskLdSku={taskLdSku}
        withViewLdFile={withViewLdFile}
      />
      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </div>
  );
}

export default ItemMessagesListMessageFile;
