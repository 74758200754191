import { IconsEnum } from '../../../../../../../../assets/icons/types';
import { ItemMessagesListMessageFileFileAttachment } from '../ItemMessagesListMessageFile.types';
import {
  MessageTaskLdCustomerID,
  MessageTaskLdSku
} from '../../../../../../messagesTypes';

import { DownloadFileAttachmentButton } from '../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentButton';
import { LdFileViewerModalButton } from '../../../../../../../modelViewers/components/modalButtons/LdFileViewerModalButton';

import { CheckPermissions } from '../../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../../helpers/Icon';
import { LinkHelper } from '../../../../../../../../helpers/links/LinkHelper';
import { SizeHelper } from '../../../../../../../../helpers/SizeHelper';

import { getFileIcon } from '../../../../../../../../utils/getFileIcon';
import { getFileAttachmentName } from '../../../../../../../fileAttachments/utils/getFileAttachmentName';

import { MessagesPermissions } from '../../../../../../messagesConstants';
import { filesKeys, words } from '../../../../../../../../locales/keys';

interface ItemMessagesListMessageFileLdModelProps {
  fileAttachment: ItemMessagesListMessageFileFileAttachment;
  isVisibleForClient: boolean;
  taskLdCustomerId?: MessageTaskLdCustomerID;
  taskLdSku?: MessageTaskLdSku;
}

function ItemMessagesListMessageFileLdModel({
  fileAttachment,
  isVisibleForClient,
  taskLdCustomerId,
  taskLdSku
}: ItemMessagesListMessageFileLdModelProps) {
  return (
    <>
      <div className="flex flex-col justify-between gap-1.5">
        <LinkHelper
          className="break-all line-clamp-3 leading-4 text-xs font-medium focus:outline-none"
          href={fileAttachment.file}
          target="_blank"
        >
          <Icon
            icon={getFileIcon(fileAttachment.file)}
            className="h-5 w-5 -mt-0.5"
          />
          {getFileAttachmentName(fileAttachment)}
        </LinkHelper>

        <div className="flex-1">
          <div className="flex gap-0.5 pt-1.5">
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_VIEW_LD_FILE_BUTTON
              }
            >
              <LdFileViewerModalButton
                className="p-1 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                i18nText="LD"
                taskLdCustomerId={taskLdCustomerId}
                taskLdSku={taskLdSku}
                modalSize="full"
                title={taskLdSku}
                tooltipI18nText={filesKeys.viewLdFile}
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON
              }
            >
              <DownloadFileAttachmentButton
                fileAttachmentUuid={fileAttachment.uuid}
                className="p-1 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.DOWNLOAD_OUTLINE}
                iconClassName="w-5 h-5"
                tooltipI18nText={words.download}
              />
            </CheckPermissions>
          </div>
        </div>

        <div className="flex items-center gap-1">
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON}
          >
            {isVisibleForClient && (
              <Icon
                icon={IconsEnum.EYE_SOLID}
                className="h-3 w-3 text-gray-500 mb-0.5"
              />
            )}
          </CheckPermissions>

          <div className="text-gray-600 dark:text-gray-400 text-xs">
            <SizeHelper size={fileAttachment.size} />
            {fileAttachment.ext ? (
              <span> &ndash; {fileAttachment.ext}</span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemMessagesListMessageFileLdModel;
